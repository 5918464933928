import "./styles.scss"

import React from "react"

import CooperationForm from "components/CooperationForm"

const Content = () => {
  return (
    <section className="cooperation-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-5 col-md-6">
            <div className="cooperation-content__inner">
              <h3>Jesteś partnerem bądź doradcą finansowym?</h3>
              <p>
                Szukasz bezpiecznej i nowoczesnej inwestycji z atrakcyjną stopą
                zwrotu z inwestycji dla swoich Inwestorów, opartej na silnych
                podstawach uznanej firmy deweloperskiej?
              </p>
              <p>
                Skontaktuj się z nami w celu omówienia szczegółów współpracy.
                Proponujemy atrakcyjne warunki i konkurencyjne wynagrodzenie.
              </p>
              <img
                src={
                  require("assets/images/cooperation/cooperation-content-image.jpg")
                    .default
                }
                alt=""
              />
            </div>
          </div>

          <div className="col-xl-6 offset-xl-1 col-md-6">
            <p className="cooperation-content__sub-header">zadzwoń</p>
            <a
              href="tel:+48 697 708 627"
              className="cooperation-content__header"
            >
              +48 697 708 627
            </a>
            <p className="cooperation-content__sub-header">
              formularz kontaktowy
            </p>
            <CooperationForm />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
