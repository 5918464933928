import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/cooperation"

const Contact = () => {
  const title = "Współpraca"

  return (
    <Layout>
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content />
    </Layout>
  )
}

export default Contact
