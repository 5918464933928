import "./styles.scss"

import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"

import Input from "components/Input"
import Checkbox from "components/Checkbox"
import Button from "components/Button"

const CooperationForm = () => {
  const formID = "550"
  const [send, setSend] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [legalFirst, setLegalFirst] = useState(false)

  const resetForm = () => {
    setSend(false)
    setName("")
    setEmail("")
    setPhone("")
    setLegalFirst(false)
  }

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("firstName", name)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("date", new Date().toLocaleString() + "")
    formData.set("legalFirst", legalFirst)

    axios({
      method: "post",
      url: `https://trust-finance.zenx.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        console.log("Submit success")
        resetForm()
        navigate("/kontakt-potwierdzenie/")
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <form onSubmit={formSubmit}>
      <Input
        placeholder="imię i nazwisko*"
        type="text"
        id="name"
        name="name"
        value={name}
        onChange={e => setName(e.target.value)}
        required
      />
      <Input
        placeholder="telefon*"
        type="phone"
        id="phone"
        name="phone"
        value={phone}
        onChange={e => setPhone(e.target.value)}
        required
      />
      <Input
        placeholder="e-mail*"
        type="email"
        id="email"
        name="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        required
      />
      <Checkbox name="legalFirst" checked="true" className="checkbox--bigger">
        Proszę o więcej informacji
      </Checkbox>
      <div className="contact-form__action contact-form__action--cooperation">
        <Checkbox
          name="legalFirst"
          checked={legalFirst}
          onChange={() => setLegalFirst(!legalFirst)}
          required
        >
          Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych przez
          Trust Investment Projekt 15 sp. z o.o., ul. Robotnicza 1, 25-662
          Kielce NIP: 9592052678, REGON: 0000976293, KRS: 0000976293
          (Administrator danych), w celu marketingu bezpośredniego dotyczącego
          własnych produktów i usług. Obowiązek informacyjny zgodnie z art. 13
          ogólnego rozporządzenia o ochronie danych osobowych (RODO) z dnia 27
          kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016): informacje o
          przetwarzaniu przez nas danych, jak również o prawach osób, których
          dane dotyczą znajdują się w naszej polityce prywatności polityce
          prywatności.
        </Checkbox>
        <Button className="button" type="submit" disabled={!legalFirst}>
          {send === true ? "Wysyłanie.." : "Wyślij"}
        </Button>
      </div>
    </form>
  )
}

export default CooperationForm
